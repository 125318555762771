import React from 'react'
import { LayoutOptionEnum } from '../constants'
import Widget from './Block'
import { WidgetEl } from './style'
import ColumnLayout from './ColumnLayout'
import GridLayout from './GridLayout'
import HorizontalLayout from './HorizontalLayout'
import ListLayout from './ListLayout'
import SingleLayout from './SingleLayout'
import VerticalLayout from './VerticalLayout'

interface Props {
  layoutType: LayoutOptionEnum
}

const WidgetLayout: React.FC<Props> = (props) => {
  const { layoutType } = props

  function renderWidgets() {
    switch (layoutType) {
      case LayoutOptionEnum.COLUMNS:
        return <ColumnLayout
        />
      case LayoutOptionEnum.GRID:
        return <GridLayout
        />
      case LayoutOptionEnum.HORIZONTAL:
        return <HorizontalLayout
        />
      case LayoutOptionEnum.LIST:
        return <ListLayout
        />
      case LayoutOptionEnum.SINGLE:
        return <SingleLayout
        />
      case LayoutOptionEnum.VERTICAL:
        return <VerticalLayout
        />
      default:
        return <>
        </>
    }
  }

  return <WidgetEl.Wrapper>
    {renderWidgets()}
  </WidgetEl.Wrapper>
}
export default WidgetLayout