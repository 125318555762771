import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown, Segment } from 'semantic-ui-react'
import Dialog from '~/components/Dialog'
import CO2THGDairyEmission from '~/components/Widgets/ChartWidget/CO2THGDairyEmission'
import Co2THGResults from '~/components/Widgets/ChartWidget/Co2THGResults'
import TextWidget from '~/components/Widgets/TextWidget'

interface Props {
  block: any
  handleClose: () => void
  handleAddNewWidget: (widgetType: WidgetType, block) => void
}

export enum WidgetType {
  CO2_THG_CHART_WIDGET = 'co2_thg_results_chart',
  TEXT_WIDGET = 'text_widget',
  CO2_INVERTORY_ADDITION_WIDGET = 'co2_inventory_addition_chart',
  CO2_CALF_REARING_WIDGET = 'co2_calf_rearing_chart',
  CO2_FEEDING_WIDGET = 'co2_feeding_chart',
  CO2_MANURE_BEDDING_WIDGET = 'co2_manure_bedding_chart'
}

export const widgetOptions = [
  {
    text: 'CO2 THG Ergebnis',
    value: WidgetType.CO2_THG_CHART_WIDGET
  },
  {
    text: 'Text widget',
    value: WidgetType.TEXT_WIDGET
  },
  {
    text: 'CO2 Bestandsergänzung',
    value: WidgetType.CO2_INVERTORY_ADDITION_WIDGET
  },
  {
    text: 'CO2 Kälberaufzucht',
    value: WidgetType.CO2_CALF_REARING_WIDGET
  },
  {
    text: 'CO2 Fütterung',
    value: WidgetType.CO2_FEEDING_WIDGET
  },
  {
    text: 'CO2 Wirtschaftsdünger und Einstreu',
    value: WidgetType.CO2_MANURE_BEDDING_WIDGET
  }
]

const AddWidgetDialog: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { block, handleClose, handleAddNewWidget } = props

  const [widgetSelected, setWidgetSelected] = useState<string | number | boolean | (string | number | boolean)[]>('')

  function renderSelectedWidget() {
    switch (widgetSelected) {
      case WidgetType.CO2_THG_CHART_WIDGET:
        return <Co2THGResults
          enableAnimations
          enableTooltips
        />
      case WidgetType.TEXT_WIDGET:
        return <TextWidget
        />
      case WidgetType.CO2_CALF_REARING_WIDGET:
      case WidgetType.CO2_FEEDING_WIDGET:
      case WidgetType.CO2_INVERTORY_ADDITION_WIDGET:
      case WidgetType.CO2_MANURE_BEDDING_WIDGET:
        return <CO2THGDairyEmission
          widgetType={widgetSelected}
          enableAnimations
        />
      default:
        return
    }
  }

  function renderWidgetOptions() {
    return <Dropdown
      selection
      options={widgetOptions}
      placeholder={t('Select widget')}
      selectOnBlur={false}
      onChange={(_, data) => setWidgetSelected(data.value)}
      value={widgetSelected}
      clearable
    />
  }

  return <Dialog
    isDialogOpen={Boolean(block)}
    handleClose={() => {
      setWidgetSelected('')
      handleClose()
    }}
    textHeader={t('Add new widget')}
    textClose={t('Cancel')}
    textSubmit={t('Submit')}
    textContent={<div>
      {renderWidgetOptions()}
      <Segment style={{ width: '100%', height: 400, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {renderSelectedWidget()}
      </Segment>
    </div>}
    handleSubmit={() => {
      handleAddNewWidget(widgetSelected as WidgetType, block)
      setWidgetSelected('')
    }
    }
  />
}
export default AddWidgetDialog