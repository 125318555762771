import React from 'react'
import Widget from '../Block'

const VerticalLayout: React.FC = () => {

  return <>
    <Widget width="45%" height="80%" />
    <div style={{ width: '45%', height: "80%", display: 'flex', flexDirection: 'column', gap: 20 }}>
      <Widget width="100%" height="30%" />
      <Widget width="100%" height="30%" />
      <Widget width="100%" height="30%" />
    </div>
  </>
}
export default VerticalLayout