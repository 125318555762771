import styled from "styled-components";

export const StyledBlock = styled.div<{ width?: string; height?: string, isDragging?: boolean }>`
  background-color: white;
  width: ${({ width }) => width || '20px'};
  height: ${({ height }) => height || '20px'};
  border: 1px solid #ddd;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: center;
  border-radius: 10px;
  .actions {
      display: none;
  }
   &: hover {
    opacity: 0.6;
    border: 2px solid #1A3175;
     .actions {
       display: flex;
       gap: 20px;
       opacity: 1 !important;
     }
   }
  ${props => props.isDragging && `
    opacity: 0.6;
    border: 2px solid #1A3175;
     .actions {
       display: flex;
       gap: 20px;
       opacity: 1 !important;
     }
    `}
`;