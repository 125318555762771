import styled from "styled-components";

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  width: 300px;
`;

const LayoutOptionCard = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 16px;
  border: ${({ selected }) => (selected ? '2px solid #1A3175' : '2px solid #ddd')};
  background-color: ${({ selected }) => (selected ? '#F0F4F9' : '')};
  border-radius: 8px;
  color: #66738B;
  cursor: pointer;

  &:hover {
    border: 2px solid #1A3175;
  }
`;

const LayoutPreview = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background-color: #66738B;
  border-radius: 5px;
  padding: 4px;
`;

const Box = styled.div<{ width?: string; height?: string }>`
  background-color: white;
  width: ${({ width }) => width || '20px'};
  height: ${({ height }) => height || '20px'};
  border: 1px solid #aaa;
`;

const LayoutInfo = styled.div`
  display: flex;
  flex-direction: column;

  & > strong {
    font-size: 14px;
    margin-bottom: 4px;
  }

  & > span {
    font-size: 12px;
    color: #666;
  }
`;

export const LayoutEl = {
  Box,
  LayoutInfo,
  LayoutOptionCard,
  LayoutPreview,
  LayoutWrapper
}
