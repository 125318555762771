import React from 'react'
import { StyledBlock } from './style'
import { Icon, Popup, SemanticCOLORS } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

export enum BlockActionType {
  ADD_NEW_BLOCK = 'add-block',
  DELETE_BLOCK = 'delete',
  ADD_WIDGET = 'add-widget'
}

interface Props {
  width: string
  height: string
  onClickAction?: (actionType: BlockActionType) => void
  displayDeleteAction?: boolean
  children?: React.ReactNode
  isDragging?: boolean
}

const Block: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const {
    width,
    height,
    onClickAction,
    displayDeleteAction,
    isDragging
  } = props

  return <StyledBlock
    width={width}
    height={height}
    onClick={() => onClickAction(BlockActionType.ADD_WIDGET)}
    isDragging={isDragging}
  >
    {props.children}
    {!props.children && <div className='actions' onClick={(e) => e.stopPropagation()}>
      <Popup
        content={t('Add new block')}
        trigger={
          <Icon
            name={'plus circle'}
            size='large'
            color={'#1A3175' as SemanticCOLORS}
            onClick={() => onClickAction(BlockActionType.ADD_NEW_BLOCK)}
          />}
      />
      {displayDeleteAction && <Popup
        content={t('Delete block')}
        trigger={<Icon
          name={'trash'}
          size='large'
          onClick={() => onClickAction(BlockActionType.DELETE_BLOCK)}
        />}
      />}
    </div>}
  </StyledBlock>
}
export default Block