import React, { useState } from 'react'
import Block, { BlockActionType } from '../Block'
import AddWidgetDialog, { WidgetType } from '../AddWidgetDialog'
import Co2THGResults from '~/components/Widgets/ChartWidget/Co2THGResults'
import TextWidget from '~/components/Widgets/TextWidget'
import CO2THGDairyEmission from '~/components/Widgets/ChartWidget/CO2THGDairyEmission'
import cloneDeep from 'lodash.clonedeep'
import { createBlockMatrix } from '../../constants'

const ColumnLayout: React.FC = () => {
  const [blocks, setBlocks] = useState([
    {
      row: 0,
      column: 0,
      width: 'calc(90% + 50px)',
      widgetType: '',
      height: '15%'
    },
    {
      row: 1,
      column: 0,
      widgetType: '',
      width: '45%',
      height: '60%',
    },
    {
      row: 1,
      column: 1,
      widgetType: '',
      width: '45%',
      height: '60%',
    }
  ])
  const [blockSelected, setBlockSelected] = useState(null)

  function renderAddWidgets() {
    return <AddWidgetDialog
      block={blockSelected}
      handleClose={() => setBlockSelected(null)}
      handleAddNewWidget={(widgetType, block) => {
        const indexBlock = blocks.findIndex(blockItem => blockItem.row === block.row && blockItem.column === block.column)
        const tempBlocks = cloneDeep(blocks)
        tempBlocks[indexBlock].widgetType = widgetType
        setBlocks(tempBlocks)
        setBlockSelected(null)
      }}
    />
  }

  function adjustWidthOfBlocks(newBlocksTempArray, rowsLength) {
    newBlocksTempArray.forEach(block => {
      if (!block?.width?.toString()?.startsWith('calc')) {
        block.width = `${Math.floor(90 / rowsLength)}%`
      }
    })
    return newBlocksTempArray
  }

  function handleBlockActions(actionType: BlockActionType, block, rowsLength) {
    const fullWidthBlock = block?.width?.toString()?.startsWith('calc')
    switch (actionType) {
      case BlockActionType.ADD_NEW_BLOCK:
        let newBlocksTempArray = [...blocks, {
          ...block,
          column: rowsLength,
        }]
        if (!fullWidthBlock) {
          newBlocksTempArray = adjustWidthOfBlocks(newBlocksTempArray, rowsLength + 1)
        }
        return setBlocks(newBlocksTempArray)
      case BlockActionType.DELETE_BLOCK:
        let newBlocksArray = blocks.filter(blockItem => !(blockItem.row === block.row && blockItem.column === block.column))
        if (!fullWidthBlock) {
          newBlocksArray = adjustWidthOfBlocks(newBlocksArray, rowsLength - 1)
        }
        return setBlocks(newBlocksArray)
      case BlockActionType.ADD_WIDGET:
        return setBlockSelected(block)
      default:
        return
    }
  }

  function renderWidget(widgetType) {
    switch (widgetType) {
      case WidgetType.CO2_THG_CHART_WIDGET:
        return <Co2THGResults />
      case WidgetType.TEXT_WIDGET:
        return <TextWidget
        />
      case WidgetType.CO2_CALF_REARING_WIDGET:
      case WidgetType.CO2_FEEDING_WIDGET:
      case WidgetType.CO2_INVERTORY_ADDITION_WIDGET:
      case WidgetType.CO2_MANURE_BEDDING_WIDGET:
        return <CO2THGDairyEmission
          widgetType={widgetType}
          height='40%'
        />
      default:
        return
    }
  }

  function renderBlocks() {
    const blocksMatrix = createBlockMatrix(blocks)
    return blocksMatrix.map((rowsBlock) => {
      return rowsBlock.map((block) => {
        const fullWidthBlock = block?.width?.toString()?.startsWith('calc')
        return <Block
          width={block.width}
          height={block.height}
          key={`block-${Math.random()}`}
          displayDeleteAction={fullWidthBlock ? rowsBlock.length > 1 : rowsBlock.length > 2}
          onClickAction={(actionType) => handleBlockActions(actionType, block, rowsBlock.length)}
        >
          {block.widgetType && renderWidget(block?.widgetType)}
        </Block>
      })
    })
  }

  return <>
    {renderBlocks()}
    {renderAddWidgets()}
  </>
}
export default ColumnLayout