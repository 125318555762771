import React from 'react'
import Widget from '../Block'

const HorizontalLayout: React.FC = () => {

  return <>
    <Widget width="100%" height="30%" />
    <Widget width="100%" height="60%" />
  </>
}
export default HorizontalLayout