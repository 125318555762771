import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import Block from "../Block";
import { createBlockMatrix } from "../../constants";

const BlockWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
`

const DroppableArea = styled.div`
  position: relative;
  width: 40%;
  &.highlight {
    box-shadow: 0px 4px 10px #1A3175;
    border: 2px dashed #1A3175;
    border-radius: 8px;
  }
`;

const getItemStyle = (draggableStyle, snapshot?) => ({
  ...draggableStyle,
  top: snapshot.isDragging ? undefined : draggableStyle.top,
});

const GridLayout: React.FC = () => {
  const [blocks, setBlocks] = useState(createBlockMatrix([
    {
      id: '0',
      row: 0,
      column: 0,
      widgetType: '',
      width: '40%',
      height: '20%'
    },
    {
      id: '1',
      row: 0,
      column: 1,
      widgetType: '',
      width: '40%',
      height: '20%',
    },
    {
      id: '2',
      row: 0,
      column: 2,
      widgetType: '',
      width: '40%',
      height: '20%',
    },
    {
      id: '3',
      row: 1,
      column: 0,
      widgetType: '',
      width: '40%',
      height: '20%'
    },
    {
      id: '4',
      row: 1,
      column: 1,
      widgetType: '',
      width: '40%',
      height: '20%',
    },
    {
      id: '5',
      row: 1,
      column: 2,
      widgetType: '',
      width: '40%',
      height: '20%',
    }
  ]))

  function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };


  function transferItemsBetweenLists(sourceList, targetList, source, destination) {
    const updatedSourceList = Array.from(sourceList);
    const updatedTargetList = Array.from(targetList);
    const [movedItem] = updatedSourceList.splice(source.index, 1);

    updatedTargetList.splice(destination.index, 0, movedItem);

    return {
      sourceList: updatedSourceList,
      targetList: updatedTargetList,
    };
  }

  function handleDragEnd(event) {
    const { source, destination } = event;

    if (!destination) return;

    const sourceIndex = Number(source.droppableId);
    const targetIndex = Number(destination.droppableId);

    if (sourceIndex === targetIndex) {
      const reorderedItems = reorder(blocks[sourceIndex], source.index, destination.index);
      const updatedState = [...blocks];
      //@ts-ignore
      updatedState[sourceIndex] = reorderedItems;
      setBlocks(updatedState);
    } else {
      const { sourceList, targetList } = transferItemsBetweenLists(
        blocks[sourceIndex],
        blocks[targetIndex],
        source,
        destination
      );

      const updatedState = [...blocks];
      //@ts-ignore
      updatedState[sourceIndex] = sourceList;
      //@ts-ignore
      updatedState[targetIndex] = targetList;

      setBlocks(updatedState.filter(list => list.length));
    }
  }

  return (
    <BlockWrapper>
      <DragDropContext onDragEnd={handleDragEnd}>
        {blocks.map((el, ind) => (
          <Droppable key={ind} droppableId={`${ind}`}>
            {(provided, snapshot) => (
              <DroppableArea
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={snapshot.isDraggingOver ? "highlight" : ""}
              >
                {el.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...getItemStyle(provided.draggableProps.style, snapshot),
                          marginBottom: '50px',
                        }}
                      >
                        <Block
                          width="100%"
                          height="125px"
                          isDragging={snapshot.isDragging}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </DroppableArea>
            )}
          </Droppable>
        ))}
      </DragDropContext>
    </BlockWrapper>
  );
}
export default GridLayout
