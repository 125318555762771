import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Layout from '~/components/Layout'
import { useForm, FormProvider } from 'react-hook-form'
import { Tab, Ref, MenuItem } from 'semantic-ui-react'
import { DairyApi } from '~/services'
import { DairyOptionsType, DairyCreateType, DairyEditType } from '~/services/dairies'
import { RouteTypes } from '~/Constants'
import { StyledTabWrapper } from './style'
import { useHistory, useParams } from 'react-router-dom'
import Box from '~/components/Box'
import notify from '~/components/notify'
//Tabs
import GeneralInformation from './Tabs/GeneralInformation'
import Modules from './Tabs/Modules'
import ImagesColors from './Tabs/ImagesColors'
import AmountUnits from './Tabs/AmountUnits'
import Redirection from './Tabs/Redirection'
import EmailNewsletter from './Tabs/EmailNewsletter'
import Location from './Tabs/Location'
import ExportImport from './Tabs/ExportImport'
import Widgets from './Tabs/Widgets'

const tabFields = {
  generalInformation: {
    fields: ['companyName', 'languages', 'subdomain', 'headerTitle', 'languageId'],
    errors: false,
    index: 0
  },
  imagesColors: {
    fields: ['firstColor', 'secondColor', 'chartBarColor', 'chartLineColor', 'cover', 'headIcon32', 'logo', 'chartBarColor2'],
    errors: false,
    index: 2
  },
  amountUnits: {
    fields: ['farmerAmount_type', 'amountUnit', 'amountVolume', 'amountPrice'],
    errors: false,
    index: 3
  },
  redirects: {
    fields: ['texts'],
    errors: false,
    index: 4
  },
  emailNewsLetter: {
    fields: ['email', 'emailFromName', 'newsletterEmail', 'newsletterEmailFromName', 'emailHeader'],
    errors: false,
    index: 5
  },
  location: {
    fields: ['name', 'streetName', 'streetNumber', 'city', 'zip', 'countryId', 'phonePrefix', 'phoneAreaCode', 'phoneNumber'],
    errors: false,
    index: 6
  }
}
const pictureFields = ['headIcon32', 'logo', 'cover']
const CreateDairyFactory: React.FC = () => {
  const { t } = useTranslation()
  const [isBusy, setIsBusy] = useState(false)
  const methods = useForm<DairyCreateType>({
    mode: 'onSubmit'
  })
  const { id } = useParams()
  const history = useHistory()
  const [activeIndex, setActiveIndex] = useState(0)
  const [dairyOptions, setDairyOptions] = useState<DairyOptionsType>(null)
  const tabRef = useRef<HTMLDivElement>()

  useEffect(() => {
    fetchOptions()
  }, [])

  const createObjectFromString = (stringArray) => stringArray.map((suffix: string) => ({ suffix: suffix }))

  async function fetchOptions() {
    setIsBusy(true)
    try {
      const dairyOptionsResponse = await DairyApi.getDairyOptions()
      if (id) {
        const response = await DairyApi.getDairy(id)
        const headIcon32Res = await DairyApi.getDairyFile(id, response.data.headIcon32)
        const logo = await DairyApi.getDairyFile(id, response.data.logo)
        const cover = await DairyApi.getDairyFile(id, response.data.cover)
        response.data.emailSuffix = createObjectFromString(response.data.emailSuffix)
        methods.reset({
          ...response.data,
          headIcon32: headIcon32Res.data,
          logo: logo.data,
          cover: cover.data
        })
      }
      setDairyOptions(dairyOptionsResponse.data)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  async function onSubmit(data: DairyCreateType | DairyEditType) {
    //@ts-ignore
    data.emailSuffix = data.emailSuffix.map((suffix) => suffix.suffix)
    if (id) {
      pictureFields.map(pictureField => {
        if (!Object.keys(methods.formState.dirtyFields).find(key => key === pictureField)) {
          delete data[pictureField]
        }
      })
    }
    setIsBusy(true)
    try {
      if (id) {
        await DairyApi.updateDairy(id, data)
      } else {
        await DairyApi.createDairy(data)
      }
      history.push(RouteTypes.DAIRY_FACTORY)
      notify(t('Successfully created/edited dairy'), 'success')
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function errorTab() {
    const { formState: { errors } } = methods
    const tabFieldsTemp = { ...tabFields }
    const errorFields = Object.keys(errors)
    Object.values(tabFieldsTemp).map(tabField => {
      let error = false
      tabField.fields.map(field => {
        if (errorFields.find(errorField => errorField === field)) {
          error = true
        }
      })
      tabField.errors = error
    })
    return tabFieldsTemp
  }

  function getMenuItem(pane, index) {
    const errors = errorTab()
    const paneHasError = Object.values(errors).find(pane => pane.index === index)
    return <MenuItem
      key={pane.menuItem}
      className='tab-item'
    >
      <div className={`tab-number ${paneHasError?.errors ? 'error' : ''}`}>{index + 1}</div>
      <div className='tab-name'>{pane.menuItem}</div>
    </MenuItem>
  }

  function renderTabs() {
    const panes = [
      {
        menuItem: t('General information'),
        component: <GeneralInformation
          languages={dairyOptions.languages}
          instanceTypes={dairyOptions?.instanceTypes}
        />
      },
      {
        menuItem: t('Modules'),
        component: <Modules
          setValue={methods.setValue}
          control={methods.control}
          modules={dairyOptions.modules}
        />
      },
      {
        menuItem: t('Images and colors'),
        component: <ImagesColors />
      },
      {
        menuItem: t('Amount units'),
        component: <AmountUnits
          dairyOptions={dairyOptions}
        />
      },
      {
        menuItem: t('Widgets'),
        component: <Widgets
        />
      },
      {
        menuItem: t('Redirections & Environments'),
        component: <Redirection
          languages={dairyOptions.languages}
        />
      },
      {
        menuItem: t('Email & Newsletter'),
        component: <EmailNewsletter setValue={methods.setValue} />
      },
      {
        menuItem: t('Location'),
        component: <Location control={methods.control} dairyOptions={dairyOptions} />
      },
      {
        menuItem: t('Export/Import'),
        component: <ExportImport
          ftpsData={dairyOptions?.ftps}
        />
      }
    ]
    if (id) {
      const locationIndex = panes.findIndex(pane => pane.menuItem === t('Location'))
      if (locationIndex >= 0) {
        panes.splice(locationIndex, 1)
      }
    }
    return <>
      <Ref innerRef={tabRef}>
        <Tab
          activeIndex={activeIndex}
          // @ts-ignore: semantic type error
          onTabChange={(_, data) => setActiveIndex(data.activeIndex)}
          renderActiveOnly={false}
          menu={{ className: 'wrapped' }}
          panes={
            panes.map((pane, index) => {
              return {
                menuItem: getMenuItem(pane, index),
                pane: <Tab.Pane
                  children={pane.component}
                  active={index === activeIndex}
                  // @ts-ignore: semantic type error
                  key={index}
                />
              }
            })}
        />
      </Ref>
    </>

  }

  return <Layout
    onSubmit={methods.handleSubmit(onSubmit)}
    title={id ? t('Edit dairy factory') : t('Create dairy factory')}
    action={t('Save')}
    segmented
    isBusy={isBusy}
    close={RouteTypes.DAIRY_FACTORY}
  >
    <StyledTabWrapper>
      {dairyOptions && <Box
        header={id ? t('Follow the steps to in order to edit Dairy') : t('Follow the steps to create new Dairy')}
        fluid
        content={<FormProvider {...methods}>
          {renderTabs()}
        </FormProvider>}
      />
      }
    </StyledTabWrapper>
  </Layout>

}
export default CreateDairyFactory
