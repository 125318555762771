import React from 'react'
import Widget from '../Block'

const ListLayout: React.FC = () => {

  return <>
    <Widget width="100%" height="25%" />
    <Widget width="100%" height="25%" />
    <Widget width="100%" height="25%" />
  </>
}
export default ListLayout