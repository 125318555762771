import React from 'react'
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { WidgetType, widgetOptions } from '~/pages/DairyFactory/Tabs/Widgets/WidgetLayout/AddWidgetDialog'

interface Props {
  widgetType: WidgetType
  height?: string
  enableAnimations?: boolean
}

const co2InvertoryData = [
  {
    yourBussines: 1769
  },
  {
    idbBavarianCow: 2104
  }
];

const co2CalfReefingData = [
  {
    yourBussines: 76
  },
  {
    idbBavarianCow: 118
  }
];

const co2FeedData = [
  {
    yourBussines: 6400
  },
  {
    idbBavarianCow: 7500
  }
];

const co2ManureData = [
  {
    yourBussines: 1200
  },
  {
    idbBavarianCow: 1100
  }
];

const CustomLegend = () => (
  <div style={{ display: 'flex', alignItems: 'center', gap: 20, justifyContent: 'center' }}>
    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
      <div
        style={{
          width: 10,
          height: 10,
          backgroundColor: '#7AB802',
          borderRadius: '2px',
        }}
      />
      <span style={{ color: '#7AB802' }}>{'Ihr Betrieb'}</span>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
      <div
        style={{
          width: 10,
          height: 10,
          backgroundColor: '#3D5599',
          borderRadius: '2px',
        }}
      />
      <span style={{ color: '#3D5599' }}>{'IDB-Bayern Kuh (LfL)'}</span>
    </div>
  </div>
);

const CO2THGDairyEmission: React.FC<Props> = (props) => {

  function getData() {
    switch (props.widgetType) {
      case WidgetType.CO2_INVERTORY_ADDITION_WIDGET:
        return co2InvertoryData
      case WidgetType.CO2_CALF_REARING_WIDGET:
        return co2CalfReefingData
      case WidgetType.CO2_FEEDING_WIDGET:
        return co2FeedData
      case WidgetType.CO2_MANURE_BEDDING_WIDGET:
        return co2ManureData
      default:
        return co2InvertoryData
    }
  }

  function getWidgetText() {
    const widget = widgetOptions.find(widgetOption => widgetOption.value === props.widgetType)
    if (widget) {
      return widget.text
    }
    return ''
  }

  return <ResponsiveContainer width='100%' height={props.height || 250}>
    <BarChart
      layout='vertical'
      data={getData()}
      margin={{ top: 40, right: 30, left: -60, bottom: 5 }}
      maxBarSize={30}
    >
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis type='number' />
      <YAxis type='category' dataKey='name' />
      <Legend content={<CustomLegend />} />
      <text
        x="50%"
        y="20"
        textAnchor="middle"
        dominantBaseline="start"
        style={{ fontSize: '16px', fontWeight: 'bold' }}
      >
        {`${getWidgetText()} (kg CO2-Äq./Kuh u. Jahr)`}
      </text>
      <Bar isAnimationActive={Boolean(props.enableAnimations)} dataKey="yourBussines" stackId="a" fill="#7AB802">
        <LabelList dataKey="yourBussines" position="center" fill="#ffffff" />
      </Bar>
      <Bar isAnimationActive={Boolean(props.enableAnimations)} dataKey="idbBavarianCow" stackId="a" fill="#3D5599">
        <LabelList dataKey="idbBavarianCow" position="center" fill="#ffffff" />
      </Bar>
    </BarChart>
  </ResponsiveContainer>
}
export default CO2THGDairyEmission