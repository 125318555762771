import React, { useState } from 'react'
import WidgetLayout from './WidgetLayout'
import styled from 'styled-components';
import { LayoutOptionEnum } from './constants';
import Layouts from './Layouts'

const Container = styled.div`
  display: flex;
  align-items: stretch;
  gap: 20px;
`;

const Section = styled.div`
  flex: 1;
  border: 1px dotted #ddd;
  padding: 16px;
  border-radius: 10px;
  background-color: #F0F4F9;
  overflow-y: auto;
`;

const Widgets: React.FC = () => {
  const [layoutType, setLayoutType] = useState<LayoutOptionEnum>(LayoutOptionEnum.COLUMNS)

  return <Container>
    <Layouts
      layoutType={layoutType}
      onChangeLayout={(value) => setLayoutType(value)}
    />
    <Section>
      <WidgetLayout
        layoutType={layoutType}
      />
    </Section>
  </Container>
}
export default Widgets