
export enum LayoutOptionEnum {
  COLUMNS = "columns",
  GRID = "grid",
  HORIZONTAL = "horizontal",
  LIST = "list",
  SINGLE = "single",
  VERTICAL = "vertical",
}

export const createBlockMatrix = (blocks) => {
  const groupedData = blocks.reduce((acc, item) => {
    if (!acc[item.row]) {
      acc[item.row] = [];
    }
    acc[item.row].push(item);
    return acc;
  }, {});

  const matrix = Object.keys(groupedData)
    .sort((a, b) => Number(a) - Number(b))
    .map(row => groupedData[row]);
  return matrix;
}