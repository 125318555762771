import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
  height: 100%;
  flex-wrap: wrap;
  justify-content: center;
`

export const WidgetEl = {
  Wrapper
}
