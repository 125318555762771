import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutOptionEnum } from '../constants';
import { LayoutEl } from './style';

interface LayoutOption {
  id: LayoutOptionEnum;
  name: string;
  description: string;
  render: React.ReactNode;
}

interface Props {
  layoutType: LayoutOptionEnum
  onChangeLayout: (layout: LayoutOptionEnum) => void
}

const layouts: React.FC<Props> = (props) => {
  const { onChangeLayout } = props
  const { t } = useTranslation()
  const [layoutType, setLayoutType] = useState<string | null>(props.layoutType);

  useEffect(() => {
    setLayoutType(props.layoutType)
  }, [props.layoutType])

  const layouts: LayoutOption[] = [
    {
      id: LayoutOptionEnum.COLUMNS,
      name: t('Columns'),
      description: 'Displays widgets in two selected categories.',
      render: (
        <LayoutEl.LayoutPreview>
          <LayoutEl.Box width="100%" height="10px" />
          <LayoutEl.Box width="40%" height="20px" />
          <LayoutEl.Box width="40%" height="20px" />
        </LayoutEl.LayoutPreview>
      ),
    },
    {
      id: LayoutOptionEnum.GRID,
      name: t('Grid'),
      description: 'Displays widgets in a grid layout.',
      render: (
        <LayoutEl.LayoutPreview>
          <LayoutEl.Box width="40%" height="15px" />
          <LayoutEl.Box width="40%" height="15px" />
          <LayoutEl.Box width="40%" height="15px" />
          <LayoutEl.Box width="40%" height="15px" />
        </LayoutEl.LayoutPreview>
      ),
    },
    {
      id: LayoutOptionEnum.HORIZONTAL,
      name: t('Horizontal Box'),
      description: 'Displays widgets in a horizontal box.',
      render: (
        <LayoutEl.LayoutPreview>
          <LayoutEl.Box width="100%" height="10px" />
          <LayoutEl.Box width="100%" height="20px" />
        </LayoutEl.LayoutPreview>
      ),
    },
    {
      id: LayoutOptionEnum.LIST,
      name: t('List'),
      description: 'Displays widgets in a list layout.',
      render: (
        <LayoutEl.LayoutPreview>
          <LayoutEl.Box width="100%" height="10px" />
          <LayoutEl.Box width="100%" height="10px" />
          <LayoutEl.Box width="100%" height="10px" />
        </LayoutEl.LayoutPreview>
      ),
    },
    {
      id: LayoutOptionEnum.SINGLE,
      name: t('Single Post'),
      description: 'Displays a single post.',
      render: (
        <LayoutEl.LayoutPreview>
          <LayoutEl.Box width="100%" height="30px" />
        </LayoutEl.LayoutPreview>
      ),
    },
    {
      id: LayoutOptionEnum.VERTICAL,
      name: t('Vertical Box'),
      description: 'Displays widgets in a vertical box.',
      render: (
        <LayoutEl.LayoutPreview>
          <LayoutEl.Box width="40%" height="40px" />
          <div style={{ width: '40%', height: 40, display: 'flex', flexDirection: 'column', gap: 4 }}>
            <LayoutEl.Box width="100%" height="10px" />
            <LayoutEl.Box width="100%" height="10px" />
            <LayoutEl.Box width="100%" height="10px" />
          </div>
        </LayoutEl.LayoutPreview>
      ),
    },
  ];

  return (
    <LayoutEl.LayoutWrapper>
      {layouts.map((layout) => (
        <LayoutEl.LayoutOptionCard
          key={layout.id}
          selected={layoutType === layout.id}
          onClick={() => {
            onChangeLayout(layout.id)
          }}
        >
          {layout.render}
          <LayoutEl.LayoutInfo>
            <strong>{layout.name}</strong>
            <span>{layout.description}</span>
          </LayoutEl.LayoutInfo>
        </LayoutEl.LayoutOptionCard>
      ))}
    </LayoutEl.LayoutWrapper>
  );
};

export default layouts;
