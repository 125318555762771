import React from 'react';
import { Bar, XAxis, YAxis, CartesianGrid, Legend, ComposedChart, Tooltip, Line, ResponsiveContainer } from 'recharts';
import { TooltipWrapper } from './style';

const co2IndustryData = [
  {
    year: "2019",
    averageEmission: 2,
    emission: 4,
  },
  {
    year: "2020",
    averageEmission: 1,
    emission: 2,
  },
  {
    year: "2021",
    averageEmission: 6,
    emission: 4,
  }
];

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    const averageEmission = payload[1].payload.averageEmission
    const emission = payload[1].payload.emission
    return (
      <TooltipWrapper>
        <div
          style={{ color: '#413ea0' }}
          dangerouslySetInnerHTML={{ __html: `THG Emission: ${emission} kg CO<sub>2</sub>-Äq./kg Milch (FPCM)` }}
        />
        <div
          style={{ color: '#ff7300' }}
          dangerouslySetInnerHTML={{ __html: `Gemittelter THG Emission: ${averageEmission} kg CO<sub>2</sub>-Äq./kg Milch (FPCM)` }}
        />
      </TooltipWrapper>
    );
  }
  return null;
};

const CustomLegend = () => (
  <div style={{ display: 'flex', alignItems: 'center', gap: 20, justifyContent: 'center' }}>
    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
      <div
        style={{
          width: 10,
          height: 10,
          backgroundColor: '#413ea0',
          borderRadius: '2px', // optional for a slightly rounded square
        }}
      />
      <span style={{ color: '#413ea0' }}>{'THG Emission'}</span>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
      <div
        style={{
          width: 10,
          height: 10,
          backgroundColor: '#ff7300',
          borderRadius: '2px',
        }}
      />
      <span style={{ color: '#ff7300' }}>{'Gemittelter THG Emission'}</span>
    </div>
  </div>
);

interface Props {
  enableTooltips?: boolean
  enableAnimations?: boolean
}

const Co2THGResults: React.FC<Props> = (props) => {
  const { enableAnimations, enableTooltips } = props

  return (
    <ResponsiveContainer width={'100%'} height='80%'>
      <ComposedChart
        data={co2IndustryData}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="year" />
        <YAxis />
        {enableTooltips && <Tooltip content={<CustomTooltip />} />}
        <Legend content={<CustomLegend />} />
        <Bar isAnimationActive={enableAnimations} dataKey="emission" barSize={50} fill="#413ea0" />
        <Line isAnimationActive={enableAnimations} type="monotone" dataKey="averageEmission" stroke="#ff7300" />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
export default Co2THGResults
